@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --border-radius: var(--mui-shape-borderRadius);
  --border-color: var(--mui-palette-divider);
  --primary-color: var(--mui-palette-primary-main);
  --background-color: var(--mui-palette-background-default);
  --background-color-rgb: var(--mui-palette-background-paperChannel);
  --header-height: 54px;
  --header-z-index: var(--mui-zIndex-appBar);
  --footer-z-index: 10;
  --customizer-z-index: var(--mui-zIndex-drawer);
  --search-z-index: var(--mui-zIndex-tooltip);
  --drawer-z-index: var(--mui-zIndex-drawer);
  --backdrop-color: rgb(var(--mui-mainColorChannels-light) / 0.5);
}

@font-face {
  font-family: 'Vazirmatn RD';
  src: url('https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@v33.003/Round-Dots/fonts/webfonts/Vazirmatn-RD[wght].woff2') format('woff2 supports variations'),
       url('https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@v33.003/Round-Dots/fonts/webfonts/Vazirmatn-RD[wght].woff2') format('woff2-variations');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Vaxzirmatn, sans-serif;
}
x
[data-mui-color-scheme='dark'] {
  --backdrop-color: rgb(23 25 37 / 0.6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-text-landing {
  background: linear-gradient(to right, #28c76f 0%, #5a4aff 47.92%, #ff3739 100%);
  background-size: 200% auto;
  background-clip: text;
  color: transparent;
  line-height: 1.2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s ease-in-out infinite alternate;
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }

  80% {
    background-position: 50% 90%;
  }

  100% {
    background-position: 91% 100%;
  }
}